import React from "react"

import "./CartoveraHeader.scss"

export default function CartoveraHeader({ data }) {
  const { images, subtitle } = data
  return (
    <section
      id="cartovera-header"
      style={{ backgroundImage: `url(${images.bgImage})` }}
    >
      <div className="cartovera-header-wrapper">
        <div className="cartovera-logo-container">
          <img
            src={images.iconLogo}
            alt={"CartoVera application icon"}
            className="cartovera-logo"
          />
        </div>
        <h1 className="cartovera-subheader">{subtitle}</h1>
        <div className="cartovera-sublogo-container">
          <img
            src={images.logoDeepconnect}
            alt={"Deepconnect icon"}
            className="cartovera-sublogo"
          />
        </div>
      </div>
    </section>
  )
}
