import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function Cartovera({ data }) {
  let i = 0
  return [
    <section className="Cartovera compose" key={i++}>
      <div className="bgImage">
        <OverviewComponent {...data} classComponent="" />

        <section className="image fixed">
          <img src={data.images.section3} alt="CartoVera application" />
        </section>

        <section className="solution">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <h2 className="noMarginTop">{data.solution.title}</h2>
                {data.solution.textFirst.map((item, i) => (
                  <p className="mb-4" key={i}>{item}</p>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="image fixed">
          <img src={data.images.section5} alt="CartoVera application" />
        </section>

        <section className="solution last">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <h2 className="noMarginTop">{data.delivered.title}</h2>
                <ul>
                  {data.delivered.list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
              <div className="col-md-5">
                <img
                  src={data.images.valueDeliverd}
                  alt="CartoVera application"
                  style={{ paddingLeft: "32px" }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>,
  ]
}
