import {
  Section3,
  Section5,
  Header,
  LogoCartovera,
  LogoDeepconnect,
  PictureCartovera,
  Value,
} from "../../images/SingleCase/Cartovera"

const dataForCartovera = {
  type: "project",
  smart: true,
  classComponent: "Cartovera",
  nextLink: "cases/2cents/",
  prevLink: "cases/crm-sap-integration/",
  data: {
    title: "CartoVera",
    subtitle:
      "A private portal aimed at fostering the collaboration and research. Its members, mostly scholars, can post and present their R&D projects, discuss them in the common groups, comment on the advancement and submit the ongoing feedback.",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "CartoVera is a consultancy business company located in Australia. Founded in 2015 and from the beginning stands at the forefront of business and technology innovation. Company is specialising in extracting insights from data.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "The Customer has faced up to the close deadlines and only a few pages had been ready for that moment when we met him. As long as technical issues, our client was up to his eyes of questions whether there is necessary need in comment involvement and, basically, sharing some user stories on feed. The project promised to be exciting, innovative among the others and voluminous that's why we were looking forward to starting.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "JavaScript",
            "ReactJS",
            "Node.JS",
            "SASS",
            "MongoDB",
            "Mercurial",
            "Gulp",
            "HTML5",
            "CSS3",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project Manager",
              count: 1,
            },
            {
              type: "Developers",
              count: 2,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
            {
              type: "Designer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "2015 - ongoing support",
              count: "",
            },
          ],
        },
        {
          type: "Client",
          content: ["Startup"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "The ready pages were performed with ExtJS which implies using high volume data resources of RAM on devices what significantly increases time of loading what deters potential users.",
        "As a company who cares about client's success and reputation we couldn't afford to leave it like that what made us to insist on changing from ExtJS to ReactJS, which allows save time thus attract more and more customers. Chosen solutions were successfully embedded.",
        "Whereas the chosen technology stack involved rebuilding the project from the very beginning, however it didn't impact on the overall deadline. So we caught the tune, and the quantity of 2000 HTML tags were reduced to 100. We applied Material UI and used library of components what has positively influenced on time.",
        "For the server-side - Node JS was a perfect solution for both MongoDB and ReactJS, since there were intentions to create some kind of horizontal scaling tools with distributed servers using pm2. The same thing is with MongoDB which supports distributed servers.",
        "Distinguishing feature on this project is using second Data Base - Postgress with PLR functions. Project performance is directly related to PLR functions, since the main option of the project is calculating and creating analytical data.",
        "In order to create charts and tables, there was applied innovative feature – embedding external resources (such as YouTube, Social networks etc.). Using those resources, it's easily to process all the statistics and make decisions, what leads to better solutions of life and makes life easier.",
      ],
    },
    delivered: {
      title: "Value delivered",
      list: [
        "ExtJS changed for ReactJS for application performance improvement ",
        "2000 HTML tags reduced to 100 tags",
        "Material UI implementation to speed up the application development ",
        "NodeJS and MongoDB implementation for horizontal scaling tools with distributed servers",
        "Second DB implementation - PostgreSQL for application performance improvement",
        "External resources implementation for better statistics procession",
      ],
    },
    images: {
      bgTop: Header,
      bgImage: PictureCartovera,
      iconLogo: LogoCartovera,
      logoDeepconnect: LogoDeepconnect,
      section3: Section3,
      section5: Section5,
      valueDeliverd: Value,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/2cents",
      id: "enjoy",
      title: "2cents",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/cartovera",
}

export default dataForCartovera
