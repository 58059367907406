import React from 'react';
import SEO from '../../components/CommonComponents/SEO';
import Layout from '../../components/CommonComponents/Layout';
import CartoveraHeader from '../../components/SingleCase/Cartovera/CartoveraHeader';
import Cartovera from '../../components/SingleCase/Cartovera';
import ContactBtnBlock from '../../components/SingleCase/ContactBtnBlock';
import Navigate from '../../components/SingleCase/Navigate';
import RelatedProjects from '../../components/SingleCase/RelatedProjects';
import dataForCartovera from '../../information/Cases/dataForCartovera';
import {mainUrl} from '../../js/config';
import '../../components/SingleCase/PortfolioComponent.scss';

export default class CartoveraPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
    }
  }

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm })
  }
  render() {
    const {
      classComponent,
      nextLink,
      prevLink,
      relatedProjects,
      data,
    } = dataForCartovera

    return (
      <section className='singleProject'>
        <SEO
          title='Cartovera Development Case — OS-System'
          description='Development Case of Scientific Software Solution - Cartovera - Online Collaboration Tool for Scientist.'
          canonical={`${mainUrl}/cases/cartovera/`}
        />
        <Layout showFormPoint={200}>
          <CartoveraHeader data={data} />
          <Cartovera data={data} />
          <Navigate
            classComponent={classComponent}
            nextLink={nextLink}
            prevLink={prevLink}
          />
          <ContactBtnBlock />
          <RelatedProjects relatedProjects={relatedProjects} />
        </Layout>
      </section>
    )
  }
}
